<template>
	<div />
</template>

<script>
/**********************************************************************************************
**** LOGOUT ***********************************************************************************
***********************************************************************************************
* logout come pagina per risolvere il bug #853
*/

export default {
	name: 'LogoutPage',
	components: {  },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
		// esegue il logout al mount
		this.$store.dispatch('logout')
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>